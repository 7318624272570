import React, { useCallback, useEffect, useState } from 'react';
import { FormField } from '@jobber/components/FormField';
import { Checkbox } from '@jobber/components/Checkbox';
import { Combobox } from '@jobber/components/Combobox';
import { Chip } from '@jobber/components/Chip';
import { Icon } from '@jobber/components/Icon';
import { getFromServer } from '../../../../components/Common/requests';
import { useSelector } from 'react-redux';
import { IoMdCloseCircle } from 'react-icons/io';
import { useAutomationFilters, useQuoteFilter } from '../../FilterQuery/AutomationFilterQuery';
import { setTemporaryFilters } from '../../../../components/Common/Validations';

function DropdownFilters({ quoteTitles }) {
  const { updateFilter } = useAutomationFilters();
  const tagSlice = useSelector((store) => store.tag);

  const [pageLoad, setPageLoad] = useState(true);
  const [title, setTitle] = useState([]);
  const [tags, setTags] = useState([]);
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    if (pageLoad) {
      setPageLoad(false);
    } else {
      const joinedTags = tags?.map((t) => t.label)?.join(',');
      updateFilter('includeTags', joinedTags);
    }
  }, [tags]);

  useEffect(() => {
    if (pageLoad) {
      setPageLoad(false);
    } else {
      const joinedTags = lineItems?.map((t) => t?.label)?.join(',');
      updateFilter('lineItemName', joinedTags);
    }
  }, [lineItems]);

  useEffect(() => {
    if (pageLoad) {
      setPageLoad(false);
    } else {
      const joinedTags = title?.map((t) => t?.label)?.join(',');
      updateFilter('title', joinedTags);
    }
  }, [title]);

  useEffect(() => {
    const filters = localStorage.getItem('tF') ? JSON.parse(localStorage.getItem('tF')) : null;
    if (filters) {
      setTitle(filters.autoQuoteTitle || []);
      setLineItems(filters.autoLineItems || []);
      setTags(filters.autoTags || []);
    }
  }, [tagSlice?.allTags]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Combobox
          // multiSelect
          onSelect={(selection) => {
            setTemporaryFilters('tF', { autoQuoteTitle: selection });
            setTitle(selection);
          }}
          label="Title"
          selected={title}
        >
          {quoteTitles?.map((title, index) => <Combobox.Option key={index + title} id={index + title} label={title} />)}
        </Combobox>
        {title?.length > 0 ? (
          <div
            style={{ position: 'absolute', top: '-10px', right: '0' }}
            onClick={() => {
              setTemporaryFilters('tF', { autoQuoteTitle: [] });
              setTitle([]);
            }}
          >
            <IoMdCloseCircle className="fs-5 pointer" />
          </div>
        ) : (
          ''
        )}
      </div>
      <div style={{ position: 'relative' }}>
        <Combobox
          // multiSelect
          onSelect={(selection) => {
            setTemporaryFilters('tF', { autoLineItems: selection });
            setLineItems(selection);
          }}
          label="Line Items"
          selected={lineItems}
        >
          {tagSlice?.lineItems?.map((t, i) => (
            <Combobox.Option id={t?.id} label={t?.name} />
          ))}{' '}
        </Combobox>
        {lineItems?.length > 0 ? (
          <div
            style={{ position: 'absolute', top: '-10px', right: '0' }}
            onClick={() => {
              setTemporaryFilters('tF', { autoLineItems: [] });
              setLineItems([]);
            }}
          >
            <IoMdCloseCircle className="fs-5 pointer" />
          </div>
        ) : (
          ''
        )}
      </div>
      <div style={{ position: 'relative' }}>
        <Combobox
          // multiSelect
          onSelect={(selection) => {
            setTemporaryFilters('tF', { autoTags: selection });
            setTags(selection);
          }}
          label="Tags"
          selected={tags}
        >
          {tagSlice?.allTags?.map((t, i) => (
            <Combobox.Option id={t?._id} label={t?.label} />
          ))}
        </Combobox>
        {tags?.length > 0 ? (
          <div
            style={{ position: 'absolute', top: '-10px', right: '0' }}
            onClick={() => {
              setTemporaryFilters('tF', { autoTags: [] });
              setTags([]);
            }}
          >
            <IoMdCloseCircle className="fs-5 pointer" />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
}

export default DropdownFilters;
