import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Checkbox } from '@jobber/components/Checkbox';
import { InputDate } from '@jobber/components/InputDate';
import { InputTime } from '@jobber/components/InputTime';
import { useJobContext } from '../contextAPI/JobContext';
import { FormField } from '@jobber/components/FormField';
import { Button } from '@jobber/components/Button';
import { InputText } from '@jobber/components/InputText';
import { InputGroup } from '@jobber/components/InputGroup';
import './customBox.css';
import CustomBox from './CustomBox';

function Schedule({ oneOffJob }) {
  const {
    disabled,
    date,
    time,
    scheduleLater,
    unscheduleVisit,
    handleSheduleLaterChange,
    setUnscheduleVisit,
    handleDateChange,
    handleTimeChange,
    recurringDate,
    setRecurringDate,
    recurringTime,
    handleRecurringTimeChange,
    repeat,
    handleRepeat,
    duration,
    handleDurationChange,
    showCustomBox,
    setShowCustomBox,
    oneOffRepeat,
    setOneOffRepeat
  } = useJobContext();

  return (
    <Card className="h-100">
      <Card.Body>
        <Row>
          <h5 className="fw-bold">Schedule</h5>
        </Row>
        <Row className="mt-2">
          <Col xxl={6}>
            {oneOffJob ? (
              <div className="mb-3 d-flex">
                <div>
                  <div className="fw-bold mb-1">Start Date</div>
                  <InputDate
                    disabled={disabled.startDate}
                    // placeholder={'Start Date'}
                    value={date.startDate}
                    onChange={(e) => handleDateChange(e, 'startDate')}
                  />
                </div>
                <div>
                  <div className="fw-bold mb-1">End Date</div>
                  <InputDate
                    disabled={disabled.endDate}
                    placeholder={'Optional'}
                    value={date.endDate}
                    onChange={(e) => {
                      if (e && e < date.startDate) {
                        handleDateChange(new Date(), 'endDate');
                      } else {
                        handleDateChange(e, 'endDate');
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="fw-bold mb-1">Start Date</div>
                <InputDate value={recurringDate} onChange={(e) => setRecurringDate(e)} />
              </div>
            )}
          </Col>
          <Col xxl={6}>
            <div className="fw-bold mb-1">Times</div>
            <div className="d-flex gap-0 justify-content-start">
              <InputTime
                disabled={oneOffJob ? date.endDate && new Date(date.endDate).toDateString() !== new Date(date.startDate).toDateString() ? oneOffRepeat === 'null' : disabled.startTime : repeat === 'null'}
                placeholder={'Start time'}
                clearable={'always'}
                value={oneOffJob ? time.startTime : recurringTime.startTime}
                onChange={(e) => (oneOffJob ? handleTimeChange(e, 'startTime') : handleRecurringTimeChange(e, 'startTime'))}
              />
              <InputTime
                disabled={
                  oneOffJob
                    ? date.endDate && new Date(date.endDate).toDateString() !== new Date(date.startDate).toDateString()
                      ? oneOffRepeat === 'null'
                      : disabled.endTime
                    : repeat === 'null'
                }
                placeholder={'End time'}
                clearable={'always'}
                value={oneOffJob ? time.endTime : recurringTime.endTime}
                onChange={(e) => (oneOffJob ? handleTimeChange(e, 'endTime') : handleRecurringTimeChange(e, 'endTime'))}
              />
            </div>
          </Col>
          {oneOffJob && date.endDate && new Date(date.endDate).toDateString() != new Date(date.startDate).toDateString() ? (
            <Col lg={6} className="mb-3">
              <div className="fw-bold mb-1">Repeats</div>
              <FormField type={'select'} value={oneOffRepeat} onChange={setOneOffRepeat}>
                <option value={'null'}>As needed - we won't prompt you</option>
                <option value={'RRULE:FREQ=DAILY'}>Daily</option>
                <option value={'RRULE:FREQ=WEEKLY;BYDAY=MO'}>Weekly</option>
                <option value={'RRULE:FREQ=MONTHLY;BYDAY=1MO'}>Monthly</option>
                {/* <option disabled>or</option>
                    <option value={'custom'}>Custom schedule...</option> */}
              </FormField>
            </Col>
          ) : (
            ''
          )}
        </Row>
        {oneOffJob && (
          <Row className="mt-2">
            <Checkbox label={'Schedule later'} checked={scheduleLater} onChange={handleSheduleLaterChange} />
            {scheduleLater && (
              <div className="mt-3">
                <Checkbox label={'Add an unscheduled visit to the calendar'} checked={unscheduleVisit}
                  onChange={setUnscheduleVisit} />
              </div>
            )}
          </Row>
        )}
        {!oneOffJob && (
          <>
            <div className="mt-3 d-flex justify-content-between">
              {/* <div className="fw-bold mb-1">Arrival window</div>
              <Button label="Add" type="tertiary" /> */}
            </div>
            <div>
              <Row>
                <Col lg={6}>
                  <div className="fw-bold mb-1">Repeats</div>
                  <FormField type={'select'} value={repeat} onChange={handleRepeat}>
                    <option value={'null'}>As needed - we won't prompt you</option>
                    <option value={'RRULE:FREQ=WEEKLY;BYDAY=MO'}>Weekly on Mondays</option>
                    <option value={'RRULE:FREQ=WEEKLY;INTERVAL=2;BYDAY=MO'}>Every 2 Weeks on
                      Mondays
                    </option>
                    <option value={'RRULE:FREQ=MONTHLY;BYMONTHDAY=18'}>Monthly on the 18th of the
                      month
                    </option>
                    {/* <option disabled>or</option>
                    <option value={'custom'}>Custom schedule...</option> */}
                  </FormField>
                </Col>
                <Col lg={6}>
                  <div className="fw-bold mb-1">Duration</div>
                  <Row>
                    <Col sm={3} className="pr-0">
                      <InputText value={duration.days}
                        onChange={(e) => handleDurationChange(e, 'days')} />
                    </Col>
                    <Col sm={9} className="pl-0">
                      <FormField type={'select'} value={duration.duration}
                        onChange={(e) => handleDurationChange(e, 'duration')}>
                        <option value={'DAYS'}>day(s)</option>
                        <option value={'WEEKS'}>week(s)</option>
                        <option value={'MONTHS'}>month(s)</option>
                        <option value={'YEARS'}>year(s)</option>
                      </FormField>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {showCustomBox && <CustomBox />}
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default Schedule;
