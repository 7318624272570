import { useCallback, useRef } from "react";


export function useDebounce(cb, delay) {
  const timeoutId = useRef(null);

  return useCallback(
    (...args) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(() => {
        cb(...args);
      }, delay);
    },
    [cb, delay]
  );
}
