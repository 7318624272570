import { Card } from "@jobber/components/Card";
import { Glimmer } from "@jobber/components/Glimmer";
import { Heading } from "@jobber/components/Heading";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router";
import { getFromServer } from "../../components/Common/requests";
import { formatNumber } from "../../components/Common/Validations";

const processData = (data) => {
  const result = data.reduce((acc, item) => {
    const propertyId = item.quoteProperty.propertyId;
    const { groupTotalCost, groupSubTotalCost, lineItems, clientFirstName, clientLastName, quoteProperty, clientPhone, clientEmail, quoteNumber, totalCost, subTotal } = item;

    // Initialize group if it doesn't exist
    if (!acc[propertyId]) {
      acc[propertyId] = { propertyId, totalSum: 0, subTotalSum: 0, groupTotalCost: 0, groupSubTotalCost: 0, lineItems: [], quote: [], name: '', email: '', phone: '', address: {} };
    }

    // Add totals and subtotals
    acc[propertyId].subTotal = subTotal;
    acc[propertyId].totalCost = totalCost;
    acc[propertyId].groupTotalCost = groupTotalCost;
    acc[propertyId].groupSubTotalCost = groupSubTotalCost;
    acc[propertyId].name = clientFirstName + ' ' + clientLastName
    acc[propertyId].email = clientEmail;
    acc[propertyId].address = quoteProperty;
    acc[propertyId].phone = clientPhone;
    acc[propertyId].quote = [...acc[propertyId].quote, quoteNumber]
    acc[propertyId].lineItems = [...acc[propertyId].lineItems, ...lineItems];
    acc[propertyId].subTotalSum += subTotal;
    acc[propertyId].totalSum += totalCost;

    return acc;
  }, {});

  // Convert the result object back into an array
  return Object.values(result);
};


function DetailsPage() {
  const navigate = useNavigate();
  const { id, name, page } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.body.classList.add("body-pd");
    window.scrollTo(0, 0);
  }, []);
  const init = async () => {
    const result = name == 'all' ? await getFromServer(`quote/${id}/${page}`) : await getFromServer(`approved-quote-details/${id}`);
    if (result.status) {
      const processedData = processData(result.data);
      console.log(result, processedData);
      setData(processedData);
    }
    setLoading(false);
    console.log(result);
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <div className="d-flex gap-2 align-items-center">
        <IoChevronBackCircleSharp
          className="fs-2 pointer"
          onClick={() => navigate(-1)}
        />

        <Heading level={1}>Quotes Details</Heading>
      </div>
      <div className="mt-4"></div>
      {data?.map((row, index) => {
        return (
          <div key={index} className="mb-5">
            <Card
              header={
                <div
                  style={{
                    backgroundColor: "#B36096",
                    height: "0.5rem",
                  }}
                ></div>
              }
            >
              <div style={{ backgroundColor: "#EEF0F2", padding: "2rem" }}>
                <Row>
                  <Col lg={6}>
                    <div className="d-flex gap-4">
                      <Heading level={2}>
                        {row?.name}
                      </Heading>
                      {/* <InlineLabel color="blue">{checkStatus(data[0])}</InlineLabel> */}
                    </div>
                  </Col>
                  <Col lg={6} className="justify-content-end d-flex gap-3">
                    <Heading level={4}>Quote # {row?.quote?.join(", ")}</Heading>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col lg={6}>
                    <Heading level={4}>Property Address</Heading>
                    <p className="mt-2">
                      {row?.address?.street} <br />
                      {row?.address?.postalCode}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <Heading level={4}>Contact Details</Heading>
                    <p className="mt-2">
                      <span>
                        {row?.phone} <br />
                        <a href={`mailto:${row?.email}`}>
                          {row?.email}
                        </a>
                      </span>
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="mt-4">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th className="fw-bolder" width={"70%"}>
                        PRODUCT / SERVICE
                      </th>
                      <th className="fw-bolder" width={"10%"}>
                        QTY
                      </th>
                      <th className="fw-bolder" width={"10%"}>
                        UNIT PRICE
                      </th>
                      <th className="fw-bolder" width={"10%"}>
                        TOTAL
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {row?.lineItems?.map((l, i) => {
                      return (
                        <tr>
                          <td className="fw-bold">
                            {l?.name}
                            <div
                              style={{ fontSize: "13px", fontWeight: "400" }}
                            >
                              {l?.description}
                            </div>
                          </td>
                          <td>{l?.quantity}</td>
                          <td>{formatNumber(l?.unitPrice)}</td>
                          <td>{formatNumber(l?.totalPrice)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        {/* This quote is valid for the next 30 days, after which values
                        may be subject to change. */}
                      </td>
                      <td colSpan={2}>
                        <Heading level={4}>Subtotal</Heading>
                      </td>
                      <td className="fw-bolder">
                        {formatNumber(name == 'all' ? row?.subTotalSum : row?.subTotal)}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colSpan={2}>
                        <Heading level={4}>Total</Heading>
                      </td>
                      <td className="fw-bolder">
                        {formatNumber(name == 'all' ? row.totalSum : row?.totalCost)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </Card>
          </div>
        )
      })}
      {loading &&
        <Card
          header={
            <div
              style={{
                backgroundColor: "#B36096",
                height: "0.5rem",
              }}
            ></div>
          }
        >
          <div style={{ backgroundColor: "#EEF0F2", padding: "2rem" }}>
            <Row>
              <Col lg={6}>
                <div className="d-flex gap-4">
                  {loading && (
                    <Glimmer
                      shape="rectangle"
                      width={300}
                      size="base"
                      timing="speed"
                    />
                  )}
                </div>
              </Col>
              <Col lg={6} className="justify-content-end d-flex gap-3">
                {loading && (
                  <Glimmer
                    shape="rectangle"
                    width={50}
                    size="base"
                    timing="speed"
                  />
                )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={6}>
                <Heading level={4}>Property Address</Heading>
                <p className="mt-2">
                  {loading && (
                    <>
                      <Glimmer
                        shape="rectangle"
                        width={300}
                        size="base"
                        timing="speed"
                      />
                      <div className="mt-3"></div>
                      <Glimmer
                        shape="rectangle"
                        width={300}
                        size="base"
                        timing="speed"
                      />
                    </>
                  )}
                </p>
              </Col>
              <Col lg={6}>
                <Heading level={4}>Contact Details</Heading>
                {loading && (
                  <>
                    <Glimmer
                      shape="rectangle"
                      width={300}
                      size="base"
                      timing="speed"
                    />
                    <div className="mt-3"></div>
                    <Glimmer
                      shape="rectangle"
                      width={300}
                      size="base"
                      timing="speed"
                    />
                  </>
                )}
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th className="fw-bolder" width={"70%"}>
                    PRODUCT / SERVICE
                  </th>
                  <th className="fw-bolder" width={"10%"}>
                    QTY
                  </th>
                  <th className="fw-bolder" width={"10%"}>
                    UNIT PRICE
                  </th>
                  <th className="fw-bolder" width={"10%"}>
                    TOTAL
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading &&
                  Array.from({ length: 3 }, (_, index) => (
                    <tr>
                      {Array.from({ length: 4 }, (_, index) => (
                        <td>
                          <Glimmer shape="rectangle" size="base" timing="speed" />
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    {/* This quote is valid for the next 30 days, after which values
                may be subject to change. */}
                  </td>
                  <td colSpan={2}>
                    <Heading level={4}>Subtotal</Heading>
                  </td>
                  <td className="fw-bolder">
                    {loading && (
                      <>
                        <Glimmer shape="rectangle" size="base" timing="speed" />
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td colSpan={2}>
                    <Heading level={4}>Total</Heading>
                  </td>
                  <td className="fw-bolder">
                    {loading && (
                      <>
                        <Glimmer shape="rectangle" size="base" timing="speed" />
                      </>
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Card>
      }
    </div>
  );
}

export default DetailsPage;
