import { FaCirclePlus } from 'react-icons/fa6';
import { putToServer } from '../../../../components/Common/requests';
import { useRef, useState } from 'react';
import { showToast } from "@jobber/components/Toast";
import { Spinner } from '@jobber/components/Spinner';

export default function QuoteNote({ getValue, row, column, table }) {
  const initialValue = getValue();
  const inputRef = useRef(null);
  const [note, setNote] = useState(initialValue?.trim() ?? "");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  if (isLoading) {
    return <Spinner size='small' />
  }

  function enableInput() {
    setNote((prev) => prev);
    setIsEditing(true);
    setTimeout(() => {
      inputRef.current?.focus();
      inputRef.current?.setSelectionRange(0, inputRef.current.value.length);
    }, 0);
  };

  function disableInput() {
    setIsEditing(false);
    showToast({ variation: 'info', message: 'Please press enter while editing to update quote' })
  };

  async function updateQuoteNote() {
    setIsLoading(true);

    try {
      const result = await putToServer('update-client-note', {
        id: row.original.client,
        notes: note?.trim(),
      });

      if (!result.status) {
        showToast({ variation: 'error', message: result.message });
        return
      }

      setNote(result?.data);
      table.options.meta.updateData(row.index, column.id, result.data);
      showToast({ variation: 'success', message: result.message });
    } catch (error) {
      showToast({ variation: 'error', message: error.message || 'An unexpected error occurred' });
    } finally {
      setIsLoading(false);
    }
  };

  function onChange(event) {
    const value = event.target.value
    if (value.startsWith(' ')) return
    setNote(event.target.value);
  };

  function onKeyDown(event) {
    if (event.key === "Enter") {
      setIsEditing(false);
      updateQuoteNote()
    }
  };

  function buttonChildren() {
    const style = {
      color: 'var(--muted)',
      margin: 0,
    }
    if (!!note) return <p style={style} onClick={enableInput} className='text-start trunc'>{note}</p>
    return <FaCirclePlus
      onClick={enableInput}
      className="fs-4 text-green animate"
    />
  }

  const textareaStyle = {
    width: '100%',
    boxSizing: 'border-box',
    resize: 'vertical',
  }

  return (
    <>
      {isEditing ? (
        <textarea
          style={textareaStyle}
          ref={inputRef}
          onClick={enableInput}
          onBlur={disableInput}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={note}
          onDoubleClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      ) : (
        <>
          {buttonChildren()}
        </>
      )}
    </>
  );
}
