import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Button } from '@jobber/components/Button'
import { Glimmer } from '@jobber/components/Glimmer'
import { InputText } from '@jobber/components/InputText'
import { Option, Select } from '@jobber/components/Select'
import { Spinner } from '@jobber/components/Spinner'
import { showToast } from '@jobber/components/Toast'
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import * as FileSaver from 'file-saver'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Col, Dropdown, DropdownItem, Row } from 'react-bootstrap'
import Datetime from "react-datetime"
import 'react-datetime/css/react-datetime.css'
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import Pagination from '../../../components/Common/Pagination'
import { formatYearDateTimeDay } from '../../../components/Common/Validations'
import { getFromServer, postToServer, putToServer } from '../../../components/Common/requests'
import QuoteSocket from '../../../socket/QuoteSocket'
import { setSearchR } from '../../../store/AdvanceQuotes/QuotesTable'
import { setTabs } from '../../../store/AdvanceQuotes/TabsSlice'
import { useDebounce } from '../../../utils/useDebounce'
import { useQuoteFilter } from '../FilterQuery/QuoteFilterQuery'
import AuditData from '../LoadData/AuditData'
import LoadData from '../LoadData/LoadData'
import TotalLabels from '../TotalLabels/TotalLabels'
import ColorPicker from './ColorPicker'
import FiltersIndex from './FiltersIndex'
import createColumns from "./NewTableRow"; // Import the function
import TableTabs from './TableTabs'
import './newTable.css'

export default function NewTable() {
  const dispatch = useDispatch();
  const [socketCalled, setSocketCalled] = useState(false);
  const [currentTabId, setCurrentTabId] = useState(localStorage.getItem('cTab') ?? undefined);
  const reduxQuotesTable = useSelector((store) => store.quotesTable);
  const { updateFilter, buildQuery, filters, addFilterList } = useQuoteFilter();
  const [search, setSearch] = useState(reduxQuotesTable.search ?? '');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const setting = JSON.parse(localStorage.getItem('userQuotesSettings'))
  const [columnOrder, setColumnOrder] = useState(setting.columnOrder);
  const [columnSizing, setColumnSizing] = useState(setting.columnWidths);
  const [editedRows, setEditedRows] = useState({});
  const [isRecycle, setIsRecycle] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ASCENDING'
  });
  const [color, setColor] = useState(localStorage.getItem('color') ?? '#000000');
  const colorChangehandler = (value) => {
    setColor(value);
    localStorage.setItem('color', value);
  };

  const getIconStyle = (key, direction) => {
    return sortConfig.key === key && sortConfig.direction === direction ? 'fs-5 text-dark' : 'fs-5 text-muted opacity-25';
  };

  const [pageYear, setPageYear] = useState(() => {
    const storedYear = localStorage.getItem('pageYear');
    return storedYear ? moment(storedYear).year() : moment().startOf('year').year();
  });

  function handlePageYear(value) {
    if (!moment.isMoment(value)) {
      console.error("Invalid date format. Expected a moment object.");
      return;
    }

    const year = value.year(); // Extract year
    setPageYear(year);
    localStorage.setItem('pageYear', year);
    localStorage.setItem('cTab', pageData?.data?.[0]?._id);
    setCurrentTabId(pageData?.data?.[0]?._id)
    setEditedRows({})
  }

  const pageParams = new URLSearchParams({ yearCreated: Number(pageYear) });
  const pageDataKey = ["pages", pageYear]
  const { data: pageData, isPending: isPagePending } = useQuery({
    queryKey: pageDataKey,
    queryFn: () => getFromServer(`pages?${pageParams}`),
    staleTime: 1000 * 60 * 5,
    retry: 2,
    enabled: !!pageYear,
  });
  dispatch(setTabs([
    ...(Array.isArray(pageData?.data) ? pageData.data : []),
    {
      tags: {
        include: [],
        exclude: []
      },
      _id: 'recycle',
      pageName: 'Recycle Page'
    }
  ]));
  useEffect(() => {
    setCurrentTabId(prev => {
      if (!prev || (prev && !pageData?.data?.some(page => page._id === prev))) {
        localStorage.setItem('cTab', pageData?.data?.[0]?._id);
        return pageData?.data?.[0]?._id
      }
      return prev
    })

  }, [pageData])
  function refetchPageData() {
    queryClient.invalidateQueries({ queryKey: ["pages"] })
  }

  const queryString = buildQuery();
  const pageId = !!currentTabId ? currentTabId : pageData?.data?.[0]?._id;
  const tableDataKey = ["page", pageId, queryString, pageYear]
  const recycleKey = ['recycle', queryString]
  const { data: tableData, isFetching: isTableFetching, refetch: refetchTableData } = useQuery({
    queryKey: !isRecycle ? tableDataKey : recycleKey,
    queryFn: ({ signal }) => getFromServer(!isRecycle ? `page/${pageId}?${queryString}` : `recycled-quotes?${queryString}`, signal),
    staleTime: 1000 * 60 * 5,
    retry: 2,
    enabled: !!pageId, // Only enable when either condition is meta
  });

  const queryClient = useQueryClient();
  const updatePagesData = (data) => {
    queryClient.setQueryData(pageDataKey, (oldData) => {
      if (!oldData) return oldData;

      const updatedData = {
        ...oldData,
        data: pageData.data.map(page => page._id === data._id ? data : page),
      }

      dispatch(setTabs([
        ...(Array.isArray(updatedData?.data) ? updatedData.data : []),
        {
          tags: {
            include: [],
            exclude: []
          },
          _id: 'recycle',
          pageName: 'Recycle Page'
        }
      ]));

      return updatedData;
    });
  }

  const updateQueryData = async (data) => {
    updatePagesData(data);
    await refetchTableData();
  };

  const startOfCurrentYear = moment().add(1, 'year').startOf('year');
  function valid(current) {
    return current.isBefore(startOfCurrentYear);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownToggleRef = useRef(null);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const keysToRemove = ["totalCost", "amountPaid", "amountOwed", "QBtotalCost", "QBamountPaid", "QBamountOwed", "totalAmountOwed"];
  const [columnVisibility, setColumnVisibility] = useState([]);

  const [prevTabId, setPrevTabId] = useState(null);
  const tabChangeHandler = async (pageId) => {
    if (pageId == 'recycle') {
      queryClient.invalidateQueries({ queryKey: recycleKey });
      setIsRecycle(true);
    } else {
      if (prevTabId === 'recycle') {
        queryClient.invalidateQueries({ queryKey: ['page'] });
      }
      setIsRecycle(false);
      localStorage.setItem('cTab', pageId);
      setCurrentTabId(pageId);
      setEditedRows({})
    }
    setPrevTabId(pageId);
    updateFilter('page', 1);
    setCurrentPage(1);
  };

  const [newTableData, setNewTableData] = useState([]);
  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem('userQuotesSettings'))
    if (isRecycle) {
      settings.columnVisibility = {
        ...settings.columnVisibility,
        totalCost: true,
        amountPaid: true,
        amountOwed: true,
        QBtotalCost: true,
        QBamountPaid: true,
        QBamountOwed: true,
        totalAmountOwed: true
      }
    } else if (tableData?.data && tableData?.data?.page?.pageName !== 'Default Page') {
      settings.columnVisibility = {
        ...settings.columnVisibility,
        ...tableData.data.page.columnPreferences
      }
    }
    setColumnVisibility(settings.columnVisibility)
    setNewTableData(tableData?.data?.quotes)
  }, [tableData])

  const columns = createColumns();

  function updateVisible(newVisible) {
    setColumnVisibility(newVisible)
    localStorage.setItem('userQuotesSettings', JSON.stringify({
      ...setting, columnVisibility: {
        ...setting.columnVisibility,
        ...newVisible()
      }
    }));
  }

  const table = useReactTable({
    data: tableData?.data?.quotes,
    columns,
    defaultColumn: {
      minSize: 50,
      maxSize: 600,
    },
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
    onColumnSizingChange: setColumnSizing,
    onColumnVisibilityChange: updateVisible,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    state: { columnOrder, columnVisibility, columnSizing },
    meta: {
      editedRows,
      newTableData,
      currentTabId,
      isRecycle,
      handleEditedRows: function (elName, row) {
        setEditedRows((old) => ({
          ...old,
          [row.id]: !old[row.id],
        }));
        if (elName === "cancel") {
          this.revertData(row.index);
        }
      },
      updateData: function (rowIndex, columnId, value) {
        queryClient.setQueryData(tableDataKey, (oldData) => {
          if (!oldData?.data?.quotes) return oldData;

          return {
            ...oldData,
            data: {
              ...oldData.data,
              quotes: oldData.data.quotes.map((row, index) => index === rowIndex ? { ...row, [columnId]: value } : row)
            },
          };
        })
      },
      editRowData: function (rowIndex, columnId, value) {
        setNewTableData((oldData) => oldData.map(
          (row, index) => index === rowIndex ? { ...row, [columnId]: value } : row
        ))
      },
      revertData: function (rowIndex) {
        setNewTableData((oldData) => oldData?.map((row, index) => index === rowIndex ? tableData.data.quotes[rowIndex] : row))
      },
      deleteData: function (itemId) {
        queryClient.setQueryData(!isRecycle ? tableDataKey : recycleKey, (oldData) => {
          if (!oldData?.data?.quotes) return oldData;

          return {
            ...oldData,
            data: {
              ...oldData.data,
              quotes: oldData.data.quotes.filter(row => row._id !== itemId)
            },
          }
        })
      }
    }
  });

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders()
    const colSizes = {}
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]
      colSizes[`--header-${header.id}-size`] = header.getSize()
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize()
    }
    localStorage.setItem('userQuotesSettings', JSON.stringify({ ...setting, columnWidths: columnSizing }))
    return colSizes
  }, [table.getState().columnSizingInfo, table.getState().columnSizing])

  const sensors = useSensors(useSensor(PointerSensor, {}), useSensor(KeyboardSensor, {}));

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder(columnOrder => {
        const oldIndex = columnOrder.indexOf(active.id)
        const newIndex = columnOrder.indexOf(over.id)
        const array = arrayMove(columnOrder, oldIndex, newIndex) //this is just a splice util
        localStorage.setItem('userQuotesSettings', JSON.stringify({ ...setting, columnOrder: array }));
        return array
      })
    }
  };

  const { mutate: mutateSettings, isPending: isSavePending } = useMutation({
    mutationFn: (body) => postToServer('page-settings', body),
    onError: (error) => {
      showToast({
        message: error,
        variation: 'error'
      });
    },
    onSuccess: (_data, variables) => {
      localStorage.setItem('userQuotesSettings', JSON.stringify(variables));
      showToast({
        message: 'Quotes settings updated successfully'
      });
    },
  })

  function saveUserSettingsHandler() {
    mutateSettings({
      columnOrder,
      columnVisibility,
      columnWidths: columnSizing,
    })
  }

  const debouncedUpdateFilter = useDebounce(updateFilter, 500);

  function exportToCSV(apiData, fileName) {
    // Step 1: Convert JSON to Worksheet
    const worksheet = XLSX.utils.json_to_sheet(apiData);

    // Step 2: Create a Workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Step 3: Write Workbook to binary string
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });

    // Step 4: Create a Blob with explicit MIME type
    const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const data = new Blob([excelBuffer], { type: mimeType });

    FileSaver.saveAs(data, fileName + '.xlsx');

    showToast({
      message: 'Excel file downloaded'
    });
  };

  const { mutate: handleExport, isPending: isExportPending } = useMutation({
    mutationFn: fetchExportData,
    onSuccess: (quotes) => {
      const newData = quotes?.flatMap((qa) => {
        return qa?.uniquePropertyAddresses?.map((q) => ({
          Name: `${qa.clientFirstName} ${qa.clientLastName}`,
          Title: qa.quoteTitle,
          Address: q.street,
          'Zip Code': q.postalCode,
          Phone: qa.clientPhone,
          Email: qa.clientEmail,
          'Total Cost': qa.totalCostAmount,
          'Amount Paid': qa.totalPaidAmount,
          'Amount Owed': qa.totalOwedAmount,
          'QB Total Cost': qa.totalQBCost,
          'QB Amount Paid': qa.amountQBPaid,
          'QB Amount Owed': qa.amountQBOwed,
          Date: formatYearDateTimeDay(qa.quote_createdAt),
          'Approved Date': formatYearDateTimeDay(qa.approved_at),
        }));
      });

      exportToCSV(newData, pageData?.data.find(page => page._id === currentTabId)?.pageName ?? 'Quotes');
      return true;
    },
    onError: (error) => {
      showToast({
        message: error.message,
        variation: 'error',
      });
    },
  });

  async function fetchExportData() {
    const result = await getFromServer(`page-excel/${currentTabId}/${false}`);
    if (!result.status) {
      throw new Error(result.message || result.error);
    }
    return result.data.quotes;
  };

  const { mutate: highlightRow } = useMutation({
    mutationFn: ({ rowId, body }) => putToServer(`highlight-quote/${rowId}/${currentTabId}`, { ...body, color }),
    onError: (error) => {
      showToast({
        message: error.message || "Something Went Wrong!",
        variation: 'error'
      });
    },
    onSuccess: (data, { rowIndex }) => {
      table.options.meta.updateData(rowIndex, 'highlight', data?.data?.[0].highlight);
      showToast({
        message: 'Quote highlighted successfully'
      });
    },
  })

  const onSort = (key) => {
    updateFilter('sort_key', key);
    if (sortConfig?.key === key && sortConfig?.direction === 'ASCENDING') {
      setSortConfig({ key, direction: 'DESCENDING' });
      updateFilter('sort_direction', 'DESCENDING');
    } else {
      setSortConfig({ key, direction: 'ASCENDING' });
      updateFilter('sort_direction', 'ASCENDING');
    }
  };

  return (
    <div>
      <QuoteSocket socketCalled={() => setSocketCalled(!socketCalled)} />
      <div>
        <div className="d-flex justify-content-between mb-3">
          <div className="col-lg-3 col-md-4 d-flex gap-2 align-items-center">
            <p className="pt-3">Show</p>
            <Select
              size="small"
              onChange={(e) => {
                updateFilter('limit', e);
                updateFilter('page', currentPage);
                setCurrentPage(1);
                setLimit(e);
                setEditedRows({})
              }}
              value={limit}
            >
              <Option value="all">All (Takes time to load)</Option>
              <Option value="10">10</Option>
              <Option value="20">20</Option>
              <Option value="50">50</Option>
            </Select>
            <p className="pt-3">entries</p>
          </div>
          <div className="d-flex gap-2 justify-content-end align-items-center">
            <Datetime
              isValidDate={valid}
              value={`${pageYear}`}
              closeOnSelect
              inputProps={{
                className: '', style: {
                  boxSizing: 'border-box',
                  border: 'none',
                  caretColor: 'transparent',
                  color: 'white',
                  outline: 'none',
                  textAlign: 'center',
                  width: '60px',
                  padding: '8px 10px',
                  backgroundColor: 'var(--green)',
                  borderRadius: '2px',
                  cursor: 'pointer',
                }
              }}
              initialViewMode='years'
              timeFormat={false}
              dateFormat="YYYY"
              onChange={handlePageYear}
            />
            <AuditData
              curentTabId={currentTabId}
              reloadData={() => {
                queryClient.invalidateQueries({ queryKey: ['page'] })
              }}
            />
            <ColorPicker color={color} colorChangehandler={colorChangehandler} />
            <LoadData handleSynQuotes={() => { queryClient.invalidateQueries({ queryKey: ['page'] }) }} />
            <div style={{ position: 'relative' }}>
              <Button label={'Manage Columns'} ref={dropdownToggleRef} onClick={toggleDropdown} />
              <div style={{ zIndex: '11', position: 'absolute' }}>
                <Dropdown show={showDropdown}>
                  <Dropdown.Menu>
                    <DropdownItem>Show and Hide Columns</DropdownItem>
                    <div className="ms-3 d-flex flex-column gap-1">
                      {table.getAllLeafColumns().map((column) => {
                        if (tableData?.data?.page?.pageName !== 'Default Page' && keysToRemove.includes(column.columnDef.accessorKey)) return null
                        return (
                          <div key={column.id} className="px-1">
                            <label>
                              <input
                                {...{
                                  type: 'checkbox',
                                  checked: column.getIsVisible(),
                                  onChange: column.getToggleVisibilityHandler(),
                                }}
                              />{' '}
                              {column.id}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {isSavePending ? (
              <Spinner size="base" />
            ) : (
              <Button label={'Save Settings'} type="secondary" onClick={saveUserSettingsHandler} />
            )}
          </div>
        </div>
      </div>
      <div className='d-flex flex-column'>
        {isPagePending ?
          <div className='w-25'>
            <Glimmer shape="rectangle" size="large" timing="speed" />
          </div> :
          <TableTabs tabChangeHandler={tabChangeHandler} setVisibleColumns={setColumnVisibility} updateQueryData={updateQueryData} refetchPageData={refetchPageData} />
        }
        <Row className="mt-3">
          <Col md={5}>
            <div className="d-flex gap-3 pt-3">
              {isExportPending ? <div style={{ aspectRatio: 1 }} ><Spinner size="base" /> </div> : <Button label={'Export'} icon="export" onClick={handleExport} />}
              <FiltersIndex />
              <InputText
                placeholder="Search"
                name="firstName"
                size="small"
                value={search}
                onChange={(e) => {
                  setEditedRows({})
                  debouncedUpdateFilter("search", e)
                  dispatch(setSearchR(e));
                  setSearch(e);
                }}
              />
            </div>
          </Col>
          <Col>
            <div className='d-flex justify-content-end gap-2'>{!isRecycle ? <>
              <TotalLabels visibleColumns={columnVisibility} totalAmount={tableData?.data?.page} loading={isTableFetching} />
              <TotalLabels visibleColumns={columnVisibility} isQB totalAmount={tableData?.data?.page} loading={isTableFetching} />
            </> : ''}</div>
          </Col>
        </Row>
        <>
          <div id='newTable' className="mt-2 rounded-lg bg-white overflow-auto position-relative">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToHorizontalAxis]}
            >
              <table
                style={{
                  ...columnSizeVars, //Define column sizes on the <table> element
                  width: table.getTotalSize(),
                }}
                className="border-collapse border border-gray-300">
                <thead className="bg-gray-100">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                        {headerGroup.headers.map((header) => (
                          <SortableHeader key={header.id} header={header} onSort={onSort} getIconStyle={getIconStyle} />
                        ))}
                      </SortableContext>
                    </tr>
                  ))}
                </thead>
                {(isPagePending || isTableFetching) ? [...Array(3)].map((_, index) => (
                  <tr key={index}>
                    {table.getVisibleFlatColumns().map((column) => (
                      <td key={column.id}>
                        <Glimmer shape="rectangle" size="base" timing="speed" />
                      </td>
                    ))}
                  </tr>
                )) : (
                  <>
                    {!!tableData?.data.quotes.length ? (
                      <TableBody table={table} pageId={!isRecycle ? pageId : 'recycle'} highlightRow={highlightRow} currentTabId={currentTabId} columnVisibility={columnVisibility} columnOrder={columnOrder} />
                    ) : (
                      <div style={{ height: '300px', display: 'grid', placeItems: 'center' }}>
                        <p>No data found</p>
                      </div>
                    )}
                  </>
                )}
              </table>
            </DndContext>
          </div>
          {!!tableData?.data.quotes.length &&
            <div className='p-2 d-flex justify-content-between'>
              <div>
                <span>{tableData?.data?.allQuotesTotal} entries before merging quotes</span> <br />
                <span>{tableData?.data?.totalQuotes} entries after merging quotes</span>
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={tableData?.data?.totalPages}
                onPageChange={(page) => {
                  setEditedRows({})
                  setCurrentPage(page);
                  updateFilter('page', page);
                }}
              />
            </div>
          }
        </>
      </div>
    </div >
  )
}

const TableBody = React.memo(({ table, pageId, highlightRow, currentTabId, columnVisibility, columnOrder }) => {
  return (
    <tbody>
      {table.getRowModel().rows.map((row) => {
        const matchingHighlight = row.original?.highlight?.find((page) => page.pageId === currentTabId);
        return (
          <tr key={`${pageId}  ${row.original._id}`}
            style={{
              backgroundColor: matchingHighlight?.isHighlight ? matchingHighlight.color || '' : ''
            }}
            className="border-bottom"
            onDoubleClick={() => {
              if (table.options.meta.isRecycle) return
              highlightRow({
                rowId: row.original.client,
                body: { isHighlight: !matchingHighlight?.isHighlight },
                rowIndex: row.index
              });
            }}
          >
            {row.getVisibleCells().map((cell) => (
              <SortableContext
                key={cell.id}
                items={columnOrder}
                strategy={horizontalListSortingStrategy}
              >
                <DragAlongCell key={cell.id} cell={cell} />
              </SortableContext>
            ))}
          </tr>
        )
      })}
    </tbody>
  )
},
  (prev, next) => ((prev.table.options.data === next.table.options.data) && (prev.columnVisibility === next.columnVisibility) && (prev.columnOrder === next.columnOrder)) //Only re-render if data changes
)

const DragAlongCell = ({ cell }) => {
  const { isDragging, setNodeRef, transform, transition } = useSortable({
    id: cell.column.id,
  })

  const style = {
    position: 'relative',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition,
    zIndex: isDragging ? 1 : 0,
    width: `calc(var(--col-${cell.column.id}-size) * 1px) !important`,
    maxWidth: 0
  }

  return (
    <td
      style={style}
      ref={setNodeRef}
      className="p-2 trunc"
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  )
}

const SortableHeader = ({ header, onSort, getIconStyle }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: header.id });

  const { isDisabled, header: headerVal, filterKey } = header.column.columnDef

  return (
    <th
      ref={!!isDisabled ? undefined : setNodeRef}
      style={{
        transform: CSS.Translate?.toString(transform),
        transition,
        position: "relative",
        width: `calc(var(--header-${header?.id}-size) * 1px)`,
        zIndex: isDragging ? 1 : 0,
      }}
      className="border p-2"
    >
      {/* Column Order Handle */}
      <div {...attributes} {...listeners} className={`headDND ${!!isDisabled && 'disabledDND'}`} />
      <div className='d-flex justify-content-between align-items-center'>
        <b style={{ zIndex: 2 }} className='position-relative'>
          {header.isPlaceholder
            ? null
            : flexRender(
              headerVal,
              header.getContext()
            )}
        </b>
        {!!filterKey &&
          <div
            className='icon-container' onClick={(e) => {
              e.stopPropagation();
              onSort(filterKey);
            }}>
            <IoMdArrowDropup className={`icon-up ${getIconStyle(filterKey, 'ASCENDING')}`} />
            <IoMdArrowDropdown className={`icon-down ${getIconStyle(filterKey, 'DESCENDING')}`} />
          </div>
        }
      </div>

      {/* Resize Handle */}
      <div
        onMouseDown={header.getResizeHandler()}
        className={`${!!isDisabled ? 'd-none' : 'resizer'} ${header.column.getIsResizing() ? 'isResizing' : ''}`}
      />
    </th >
  );
};
