import React, { useContext, useEffect, useState } from 'react';

import { Button } from '@jobber/components/Button';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';

import { showToast } from '@jobber/components/Toast';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getFromServer } from '../../../components/Common/requests';
import { SocketContext } from '../../../SocketProvider';
import ConfirmModal from '../../Index/TagCloudCategory/ConfirmModal';
import { useQuoteFilter } from '../FilterQuery/QuoteFilterQuery';

function AuditData({ reloadData, curentTabId }) {
  const { buildQuery } = useQuoteFilter();
  const { socket, socketHandler } = useContext(SocketContext);
  const [comingMsg, setComingMsg] = useState(null);
  const [progressValue, setProgressValue] = useState(1);
  const [auditModal, setAuditModal] = useState(false);

  const userStore = useSelector((store) => store.user);
  const user = userStore?.user?.data;

  useEffect(() => {
    if (comingMsg) {
      setProgressValue(comingMsg?.progress);
    }
  }, [comingMsg]);

  useEffect(() => {
    if (!socket) {
      const newSocket = io(process.env.REACT_APP_API_URL);
      socketHandler(newSocket);
    }

    if (socket) {
      socket.on(`Genie_Audit_progress_${user?._id}`, (notify) => {
        setComingMsg(notify);
      });
    }
  }, [socket]);

  const joinRoom = async () => {
    if (socket && user?._id) {
      await socket.emit('joinGenieRoom', { userId: user?._id });
    }
  };

  useEffect(() => {
    joinRoom();
  }, [user?._id, socket]);

  const [loading, setLoading] = useState(false);

  const auditHandler = async () => {
    setAuditModal(false);
    const queryString = buildQuery();
    setLoading(true);
    const result = await getFromServer(`audit-quote/${curentTabId}?${queryString}`);
    if (result.status) {
      setLoading(false);
      setProgressValue(1);
      showToast({
        message: 'All rows audit successfully'
      });
      reloadData();
    } else {
      if (result.message?.inlcudes('Token is expired')) {
        localStorage.removeItem("authUser");
        window.location.href = '/login'
      }
      showToast({
        message: result.message || result.error,
        variation: 'error'
      });
    }
    setLoading(false);
    setProgressValue(1);
  };

  return (
    <>
      {auditModal && (
        <ConfirmModal
          buttonName={'Audit'}
          onConfirm={auditHandler}
          title={'Warning'}
          message="Are you sure? We recommend taking backup"
          open={auditModal}
          onCancel={() => setAuditModal(false)}
        />
      )}
      {loading ? (
        <div className="mb-2">
          <ProgressBar animated variant="success" label={`${progressValue}%`} now={progressValue} />
          <p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }} className="mt-1">
            Do not perform any operation, while we are auditing
          </p>
        </div>
      ) : (
        <>
          <Button label={'Audit Quotes'} onClick={() => setAuditModal(true)} />
        </>
      )}
    </>
  );
}

export default AuditData;
