import { Button } from "@jobber/components/Button"
import { Card } from "@jobber/components/Card"
import { Content } from "@jobber/components/Content"
import { Heading } from "@jobber/components/Heading"
import { InputNumber } from "@jobber/components/InputNumber"
import { Spinner } from "@jobber/components/Spinner"
import { StatusLabel } from "@jobber/components/StatusLabel"
import { Text } from "@jobber/components/Text"
import { showToast } from "@jobber/components/Toast"
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { format } from "date-fns"
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router"
import { getFromServer, postToServer } from "../../components/Common/requests"
import ResetSidebar from "../../components/Common/ResetSidebar"

export function EditQuote() {
  const { pageId, clientId } = useParams()
  const navigate = useNavigate()

  const queryKey = ['quotes', pageId, clientId]
  const { data: quotes, isFetching: isQuotesDataFetching } = useQuery({
    queryKey,
    queryFn: ({ signal }) => getFromServer(`client/${clientId}/${pageId}`, signal),
    enabled: !!pageId && !!clientId,
  })

  function formatDate(date) {
    return format(new Date(date), "MMM dd, yyyy");
  }

  function handleUpdate() {
    const quotesData = quotes.data.quotes.map((quote) => {
      return {
        quoteId: quote._id,
        amountPaid: quote.amountPaid,
        subTotal: quote.subTotal
      }
    })
    mutate({ quotesData })
  }

  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ quotesData }) => postToServer('update-quote-payment', { quotesData }),
    onMutate: ({ quotesData }) => {
      const hasInvalidAmount = quotesData.some(quote => quote.amountPaid > quote.subTotal);
      if (hasInvalidAmount) {
        throw new Error('Please provide valid amount(s)')
      }
    },
    onError: (error) => {
      showToast({
        message: error.message,
        variation: 'error'
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['page'] })
      navigate(-1)
    }
  })


  function updateData(quoteId, value) {
    queryClient.setQueryData(queryKey, (oldData) => {
      const quotes = oldData.data.quotes.map((quote) => quote._id === quoteId ? { ...quote, amountPaid: value } : quote)
      // const totalPaidAmount = quotes.reduce((acc, quote) => acc + quote.amountPaid, 0)
      return { ...oldData, data: { ...oldData.data, quotes } }
    })
  }

  function handleCancel() {
    navigate(-1)
  }

  if (isQuotesDataFetching) {
    return <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Spinner />
    </div>
  }

  return (
    <div className="d-flex flex-column gap-3 pb-4">
      <ResetSidebar />
      <Heading level={2}>Quotes for {quotes?.data?.name}</Heading>
      {quotes?.data?.quotes.map(quote => (
        <React.Fragment key={quote._id} >
          <Card header='Quote details:'>
            <Content>
              <div className="gridTable">
                <Heading level={5}>Title:</Heading>
                <Text>{quote.quoteTitle}</Text>

                <Heading level={5}>Address:</Heading>
                <Text>{quote.quoteProperty.street}</Text>

                <Heading level={5}>Zip Code:</Heading>
                <Text>{quote.quoteProperty.postalCode}</Text>

                <Heading level={5}>Quote number:</Heading>
                <Text>#{quote.quoteNumber}</Text>

                <Heading level={5}>Quote Status:</Heading>
                <div>
                  <StatusLabel label={quote.quoteStatus} alignment={"start"} status={"success"} />
                </div>

                <Heading level={5}>Approved:</Heading>
                <Text>{formatDate(quote.approved_at)}</Text>

                <Heading level={5}>Sub Total:</Heading>
                <Text>${quote.subTotal}</Text>
              </div>
              <InputAmount initialValue={quote.amountPaid} maxAmount={quote.subTotal} quoteId={quote._id} updateData={updateData} />
            </Content>
          </Card>
        </React.Fragment>
      ))}
      <div className="d-flex justify-content-between">
        <Button variation="destructive" type="secondary" label='Cancel' onClick={handleCancel} />
        {isPending ? <Spinner /> : <Button label='Update' onClick={handleUpdate} disabled={isPending} />}
      </div>
    </div>
  )
}

function InputAmount({ initialValue, quoteId, updateData, maxAmount }) {
  const [amountPaid, setAmountPaid] = useState(initialValue)

  return (
    <InputNumber
      min={0}
      max={maxAmount}
      validations={{
        required: 'Cannot be empty',
        validate: (value) => value > maxAmount && 'Amount cannot be greater than sub total'
      }}
      value={amountPaid}
      placeholder="Amount Paid"
      prefix={{ label: "$" }}
      onBlur={() => updateData(quoteId, amountPaid)}
      onChange={setAmountPaid} />
  )
}
