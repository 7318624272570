import { Heading } from '@jobber/components/Heading';
import { showToast } from '@jobber/components/Toast';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { getFromServer } from '../../components/Common/requests';
import ResetSidebar from '../../components/Common/ResetSidebar';
import ShowAppliedFilters from '../../components/Common/ShowAppliedFilters';
import { addUniqueIds } from '../../components/Common/Validations';
import { setAllTags } from '../../store/tagsSlice';
import { QuoteFilterProvider } from './FilterQuery/QuoteFilterQuery';
import NewTable from './Table/NewTable';

function AdvanceQuotes() {
  // TODO: Remove this logic once jquery-resizable-columns is removed permanently
  const STYLESHEET_URL = "https://cdn.jsdelivr.net/npm/jquery-resizable-columns@0.2.3/dist/jquery.resizableColumns.min.css";
  const SCRIPT_SRC = "https://cdn.jsdelivr.net/npm/jquery-resizable-columns@0.2.3/dist/jquery.resizableColumns.min.js";

  const existingScript = document.querySelector(`script[src="${SCRIPT_SRC}"]`)
  if (existingScript) {
    existingScript.remove();
  }
  const existingStylesheet = document.querySelector(`link[href="${STYLESHEET_URL}"]`)
  if (existingStylesheet) {
    existingStylesheet.remove();
  }

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const init = async () => {
      const result = await getFromServer('Client-Tags');
      if (result.status) {
        const addUniqueId = addUniqueIds(result.data);
        dispatch(setAllTags(addUniqueId));
      } else {
        showToast({
          message: result.message || result.error,
          variation: 'error'
        });
      }
    };
    init();

    // TODO: Remove this logic once jquery-resizable-columns is removed permanently
    return () => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = STYLESHEET_URL;
      document.head.appendChild(link);

      const script = document.createElement("script");
      script.src = SCRIPT_SRC;
      script.type = "text/javascript";
      document.head.appendChild(script);
    }
  }, []);

  return (
    <QuoteFilterProvider>
      <ResetSidebar />
      <div style={{ height: '100%' }} className="row">
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <Heading level={1}>{location?.pathname.includes('recycle') ? 'Recycle' : 'Advance'} Quotes</Heading>
          </div>
          <ShowAppliedFilters page={'quotes'} />
          <NewTable />
        </div>
      </div>
    </QuoteFilterProvider>
  );
}

export default AdvanceQuotes;
