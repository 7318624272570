import { useMemo } from 'react';
import { IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io';
import { Heading } from '@jobber/components/Heading';
import { Divider } from '@jobber/components/Divider';
import TableTagSVG from './TableTagSVG';
import { getCompleteAddress, checkStatus, formatDateTimeDay } from '../../components/Common/Validations';

const useTableColumns = (updateFilter, tagCloud) => {
  const columns = useMemo(() => [
    {
      accessorKey: 'name',
      header: () => (
        <>
          <span>Name</span>
          <div
            className=" d-flex flex-column  cursor-pointer"
            onClick={() => updateFilter('sortKey', 'PRIMARY_NAME')}
            >
            <IoMdArrowDropup size={14} />
            <IoMdArrowDropdown size={14} />
          </div>
            </>
      ),
      cell: info => {
        const tags = (info.row.original.tags && info.row.original.tags.length > 0)
          ? info.row.original.tags
          : tagCloud;
        return (
          <p>
            <a href={info.row.original.jobberWebUri} target="_blank" rel="noreferrer" className="style__none">
              <Heading level={5}>{info.getValue()}</Heading>
              <p style={{ color: 'var(--muted)' }}>{info.row.original.secondaryName}</p>
            </a>
            {tags && tags.length > 0 && (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', marginTop: '0.5rem' }}>
                {tags.map((t, index) => (
                  <TableTagSVG key={index} text={t.label} />
                ))}
              </div>
            )}
          </p>
        );
      },
    },
    {
      accessorKey: 'address',
      header: 'Address',
      cell: info => (
        <p>
          {info.row.original.clientProperties &&
            info.row.original.clientProperties.length > 0 ? (
            info.row.original.clientProperties.map((c, index, array) => (
              <div key={index}>
                {getCompleteAddress(c)}
                {index !== array.length - 1 && <Divider direction="horizontal" />}
              </div>
            ))
          ) : (
            <div>0 Properties</div>
          )}
        </p>
      ),
    },
    {
      accessorKey: 'email',
      header: 'Email',
      cell: info => (
        <p>
          {info.row.original.email?.map(e => e.address).join(' / ')}
        </p>
      ),
    },
    {
      accessorKey: 'phone',
      header: 'Phone no.',
      cell: info => (
        <p>
          {info.row.original.phones?.map(p => p.friendly).join(' / ')}
        </p>
      ),
    },
    {
      accessorKey: 'companyName',
      header: 'Company Name',
      cell: ({getValue})=> (
        <p>
          {getValue() || ''}
        </p>
      ),
    },
    {
      accessorKey: 'properties',
      header: 'Properties',
      cell: info => (
        <p>
          {info.row.original.clientProperties?.length || 0}
        </p>
      ),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: info => (
        <p>
          {checkStatus(info.row.original)}
        </p>
      ),
    },
    {
      accessorKey: 'activity',
      header: () => (
        <>
          <span>Last Activity</span>
          <div
            className="icon-container d-flex flex-column align-items-center cursor-pointer"
            onClick={() => updateFilter('sortKey', 'UPDATED_AT')}
            >
            <IoMdArrowDropup size={18} />
            <IoMdArrowDropdown size={18} />
          </div>
            </>
      ),
      cell: info => (
        <p>
          {formatDateTimeDay(info.row.original.updatedAt)}
        </p>
      ),
    },
  ], [updateFilter, tagCloud]);

  return columns;
};

export default useTableColumns;
