import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Disclosure } from "@jobber/components/Disclosure";
import { Divider } from "@jobber/components/Divider";
import { Text } from "@jobber/components/Text";
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io } from "socket.io-client";
import { postToServer } from '../../../../components/Common/requests';
import { SocketContext } from '../../../../SocketProvider';
import { setLoginUser } from '../../../../store/userSlice';
import useGetFromServer from '../../../../utils/useGetFromServer';
import ActionModal from '../ActionModal/Modal';
import DraftModal from '../DraftModal/Modal';
import Footer from '../Footer/footer';
import { Checkbox } from "@jobber/components/Checkbox";
import { showToast } from "@jobber/components/Toast";

export default function MainSection({ titles, selectedYear, tags, setLoading, setProgressLoading, processedPage }) {
  const { data: lineItemData } = useGetFromServer('lineItem')

  if (!titles.length) {
    return (
      <div style={{
        height: '80%',
        width: '100%',
        display: 'grid',
        placeItems: 'center'
      }}>
        <p>No data to display (Select title)</p>
      </div >
    )
  }

  return (
    <div className='mt-2 d-flex flex-column gap-3'>
      {titles.map((title, index) => {
        if (!!title) {
          return (
            <React.Fragment key={`${index}-${title}`}>
              <SingleTitle lineItemData={lineItemData} title={title} selectedYear={selectedYear} tags={tags} setLoading={setLoading} setProgressLoading={setProgressLoading} processedPage={processedPage} />
            </React.Fragment>
          )
        }
      })}
    </div>
  )
}

function SingleTitle({ title, selectedYear, tags, setLoading, setProgressLoading, lineItemData, processedPage }) {
  const dispatch = useDispatch();
  const userStore = useSelector((store) => store.user);
  const [page, setPage] = useState(1)
  const [pageProcess, setPageProcess] = useState(1)
  const [limit, setLimit] = useState(1500)
  const [modalOpen, setModalOpen] = useState(false)
  const { socket, socketHandler } = useContext(SocketContext);
  const user = userStore?.user?.data
  const initialLineItemRef = useRef([])

  useEffect(() => {
    setPageProcess(1)
    setPage(1)
    setLimit(1500)
  }, [selectedYear])

  const baseParams = {
    title,
    selectedYear,
    pageProcess,
    limit,
  }

  const params = {
    title,
    selectedYear,
    page,
    limit,
    includedTags: tags.include.join(';'),
    excludedTags: tags.exclude.join(';')
  };

  const [refetch, setRefetch] = useState(false)

  useEffect(() => {
    if (!socket) {
      const newSocket = io(process.env.REACT_APP_API_URL);
      socketHandler(newSocket);
    }

    if (socket) {
      socket.on(`JobberQuoteProcessRefetch_${title}_${user?._id}`, (msg) => {
        setRefetch(!refetch)
      });
    }
  }, [socket])

  const { data, loading } = useGetFromServer(`prep-quotes?${new URLSearchParams(params)}`, [selectedYear, page, limit, tags, refetch])

  const { data: prepData, loading: prepLoading } = useGetFromServer(`processed-quotes?${new URLSearchParams(baseParams)}`, [selectedYear, pageProcess])

  // function handleLimitChange(limit) {
  //   setLimit(limit)
  //   setPage(1)
  // }

  function handlePageChange(page) {
    setPage(page)
  }

  function handlePageProcessChange(page) {
    setPageProcess(page)
  }

  const quoteIdsRef = useRef([])
  function handleModalOpen(quotes) {
    setModalOpen(true)
    quoteIdsRef.current = quotes
  }

  function handleModalClose() {
    setModalOpen(false)
  }

  function modifyArray(arr) {
    if (arr.length === 1) {
      return arr[0].name.replace(/,/g, ''); // Access the `name` property
    }
    return arr.slice(0, -1)
      .map(obj => obj.name.split(" ")[0].replace(/,/g, '')) // Use `name` from each object
      .join(", ") + ', ' + arr[arr.length - 1].name.replace(/,/g, ''); // Handle the last item
  }

  const [draftModalOpen, setDraftModalOpen] = useState(false)
  const clientWithDrafts = useRef([])
  function handleDraftModalClose() {
    setDraftModalOpen(false)
  }

  const actionsRef = useRef({})
  const lineItemsRef = useRef([])
  const lineTextRef = useRef([])
  const priceRef = useRef(0)
  async function handledraftModalSubmit(quotesIds) {
    handleDraftModalClose()
    setProgressLoading(true)
    const body = {
      quoteIds: quoteIdsRef.current,
      isPreprocessed: true,
      title: data.title,
      actions: actionsRef.current,
      excludeQuotes: quotesIds,
      priceIncrease: priceRef.current,
      lineItems: lineItemsRef.current,
      textLineItems: lineTextRef.current
    }
    const response = await postToServer('process-quotes', body)
    if (response?.status) {
      dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: true, progress: '1' } }));
    } else {
      dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: false, progress: '1' } }));
      setProgressLoading(false)
      setLoading(false)
    }
  }

  async function handleModalSubmit(actions, price, lineItems, lineText) {
    actionsRef.current = actions
    lineItemsRef.current = lineItems
    lineTextRef.current = lineText
    priceRef.current = price
    if (quoteIdsRef.current.length === 0) {
      showToast({
        message: 'Please select at least one quote',
        variation: 'error'
      })
    }
    setLoading(true)
    const body = {
      quoteIds: quoteIdsRef.current,
      title: data.title,
      isPreprocessed: false,
      actions,
      priceIncrease: price,
      lineItems,
      textLineItems: lineText
    }
    const response = await postToServer('process-quotes', body)
    if (response?.status) {
      if (response.data?.clientWithDrafts) {
        clientWithDrafts.current = response?.data?.clientWithDrafts
        setDraftModalOpen(true);
        setLoading(false)
      } else {
        setLoading(false)
        setProgressLoading(true)
        dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: true, progress: '1' } }));
      }
    } else {
      dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: false, progress: '1' } }));
      setLoading(false)
      setProgressLoading(false)
    }
  }

  const currentPage = !processedPage ? data : prepData;
  const currentLoading = !processedPage ? loading : prepLoading;

  if (currentLoading) {
    return (
      <div aria-hidden="true" className='placeholder-glow'>
        <span style={{ height: '50px' }} class="rounded placeholder w-100"></span>
      </div>
    )
  }

  if (currentPage?.quotes?.length === 0) {
    return (
      <>
        <div className='border rounded p-2' style={{ display: 'grid', placeItems: 'center' }}>
          <p className='m-0'>{currentPage?.title}</p>
          <p className='m-0'>{!processedPage ? '(No Quote Found To Process)' : '(No Processed Quote Found)'}</p>
        </div>
        <Footer
          totalPages={currentPage.totalPages}
          currentPage={currentPage.currentPage}
          handlePageChange={!processedPage ? handlePageChange : handlePageProcessChange}
        // handleLimitChange={handleLimitChange}
        />
      </>
    )
  }

  const individualLength = currentPage?.quotes?.reduce((total, obj) => {
    if (Array.isArray(obj.group)) {
      return total + obj.group?.length;
    }
    return total;
  }, 0)


  return (
    <>
      <DraftModal clientWithDrafts={clientWithDrafts.current} modalOpen={draftModalOpen} handleModalClose={handleDraftModalClose} handleSubmit={handledraftModalSubmit} />
      <Disclosure title={`${currentPage?.title} (Quotes: ${individualLength} / ${currentPage?.totalDocuments} | Page: ${currentPage.currentPage})`}>
        {modalOpen && <ActionModal data={lineItemData} initialLineItemData={initialLineItemRef.current} modalOpen={modalOpen} handleModalClose={handleModalClose} handleSubmit={handleModalSubmit} />}
        <Content>
          {currentPage?.quotes?.map(quote => (
            <QuoteContent quote={quote} handleModalOpen={handleModalOpen} initialLineItemRef={initialLineItemRef} processedPage={processedPage} modifyArray={modifyArray} />
          ))}
          <Footer
            totalPages={currentPage.totalPages}
            currentPage={currentPage.currentPage}
            handlePageChange={!processedPage ? handlePageChange : handlePageProcessChange}
          // handleLimitChange={handleLimitChange}
          />
        </Content>
      </Disclosure>
    </>
  )
}

function QuoteContent({ quote, handleModalOpen, initialLineItemRef, processedPage, modifyArray }) {
  const title = modifyArray(quote.lineItemNames)
  const [quoteIds, setQuoteIds] = useState([])
  function clickHandler() {
    handleModalOpen(quoteIds);
    initialLineItemRef.current = quote?.lineItemNames
  }

  function selectHandler() {
    if (quoteIds.length === 50 || quoteIds.length === quote.group.length) {
      setQuoteIds([])
    } else {
      setQuoteIds(quote.group.slice(0, 50).map(obj => obj._id))
    }
  }

  function selectLabel() {
    let label = quote.group.length <= 50 ? 'Select All' : 'Select 50'
    if (quoteIds.length === 50 || quoteIds.length === quote.group.length) {
      return 'Deselect'
    }
    return label
  }

  if (!quote?.group.length) return null
  return (
    <React.Fragment key={title}>
      <div className='px-4'>
        <Disclosure title={`${title} (${quote.group.length})`}>
          {!processedPage &&
            <div className="d-flex gap-2">
              <Button icon='task' iconOnRight onClick={clickHandler} label='Actions' disabled={quote?.group.every(obj => obj.isProcessed === true) || !quoteIds.length} />
              <Button icon='add' onClick={selectHandler} label={selectLabel()} />
            </div>
          }
          <VirtualizedContent quotes={quote.group} selected={quoteIds} setSelected={setQuoteIds} processedPage={processedPage} />
        </Disclosure>
        <Divider direction={"horizontal"} />
      </div>
    </React.Fragment>
  )
}

function VirtualizedContent({ quotes, selected, setSelected, processedPage }) {
  const parentRef = useRef();

  const rowVirtualizer = useVirtualizer({
    count: quotes.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35, // Adjust according to the estimated height of each quote item
  });

  function onChangeHandler(val, virtualRow) {
    if (val) {
      setSelected([...selected, quotes[virtualRow.index]._id])
    } else {
      setSelected(selected.filter(quote => quote !== quotes[virtualRow.index]._id))
    }
  }

  return (
    <div style={{ flex: '1' }}>
      <Content>
        <div ref={parentRef} style={{ maxHeight: '400px', overflowY: 'auto', position: 'relative' }}>
          <div style={{
            display: 'grid',
            gridTemplateColumns: `${!processedPage ? '.2fr' : ''}  3fr 1fr 1fr`,
            gap: '0.5rem',
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1,
            padding: '0.2rem 0',
          }}>
            {!processedPage && <p className='m-0'><b>{selected.length}</b></p>}
            <p className='m-0'><b>Original Quotes</b></p>
            <p className='m-0'><b>Processed?</b></p>
            <p className='m-0'><b>Quotes Processed</b></p>
          </div>
          <div
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow, index) => (
              <div
                key={quotes[virtualRow.index].quoteNumber + index}
                ref={virtualRow.measureElement}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualRow.start}px)`,
                  display: 'grid',
                  gap: '0.5rem',
                  gridTemplateColumns: `${!processedPage ? '.2fr' : ''} 3fr 1fr 1fr`,
                }}
              >
                {!processedPage && <Checkbox onChange={(val) => onChangeHandler(val, virtualRow)} checked={selected.includes(quotes[virtualRow.index]._id)} />}
                <Text>{`${quotes[virtualRow.index].clientName} Q#${quotes[virtualRow.index].quoteNumber}`}</Text>
                <Text>{`${quotes?.[virtualRow.index]?.isProcessed ?? 'false'}`}</Text>
                <Text>
                  {
                    quotes?.[virtualRow.index]?.isProcessed ?
                      `Q#${quotes?.[virtualRow.index]?.quoteNumber} -> Q#${quotes?.[virtualRow.index]?.newQuoteNumber}`
                      :
                      ''
                  }
                </Text>
              </div>
            ))}
          </div>
        </div>
      </Content>
    </div>
  );
}
