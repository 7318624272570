import { Divider } from "@jobber/components/Divider";
import { Heading } from "@jobber/components/Heading";
import React from "react";
import { SiQuickbooks } from "react-icons/si";
import { Link } from "react-router-dom";
import JobberLogo from "../../../assets/logoBlack.svg";
import { formatNumber, formatNumberWithSpaces, formatYearDateTimeDay } from "../../../components/Common/Validations";
import Actions from "./_components/Action";
import EditCell from "./_components/EditCell";
import QuoteNote from "./_components/QuoteNote";

const createColumns = () => [
  {
    accessorKey: "notes",
    header: "Notes",
    cell: QuoteNote,
  },
  {
    accessorKey: "name",
    filterKey: "CLIENT_NAME",
    header: "Name",
    cell: ({ row }) => (
      <a href={row.original.jobberWebUri} target="_blank" className={`style__none`}>
        <Heading level={5}><span className='trunc d-block'>{row.original.clientName}</span></Heading>
        <p className=" trunc" style={{ color: 'var(--muted)' }}>{row.original.clientSecondaryName}</p>
      </a>
    ),
  },
  {
    accessorKey: "title",
    filterKey: "TITLE",
    header: "Title",
    cell: ({ row }) => row.original.quoteTitle,
  },
  {
    accessorKey: "address",
    filterKey: "ADDRESS",
    header: "Address",
    cell: ({ row }) =>
      row.original.uniquePropertyAddresses.map((c, index, array) => (
        <React.Fragment key={index}>
          {c.street}
          {index !== array.length - 1 && <Divider direction={"horizontal"} />}
        </React.Fragment>
      )),
  },
  {
    accessorKey: "zipCode",
    filterKey: "POSTAL_CODE",
    header: "Zip Code",
    cell: ({ row }) =>
      row.original.uniquePropertyAddresses.map((c, index, array) => (
        <React.Fragment key={index}>
          {c.postalCode || "-"}
          {index !== array.length - 1 && <Divider direction={"horizontal"} />}
        </React.Fragment>
      )),
  },
  {
    accessorKey: "phone",
    filterKey: "CLIENT_PHONE",
    header: "Phone",
    cell: ({ row }) => row.original.clientPhone,
  },
  {
    accessorKey: "email",
    filterKey: "CLIENT_EMAIL",
    header: "Email",
    cell: ({ row }) => row.original.clientEmail,
  },
  {
    accessorKey: "approved",
    header: "Approved",
    cell: ({ row }) => row.original.totalApprovedQuotes,
  },
  {
    accessorKey: "details",
    header: "Details",
    cell: ({ row, table }) => (
      <>
        {!table.options.meta.isRecycle ? (
          <Link
            to={{ pathname: `/advance-quotes/all/${row.original.client}/${table.options.meta.currentTabId}` }}
            className="text-primary animate text-decoration-underline"
          >
            Details
          </Link>
        ) : (
          'Restore to view details'
        )}
      </>
    ),
  },
  {
    accessorKey: "totalCost",
    filterKey: "TotalCost",
    header: () => (<><img src={JobberLogo} style={{ width: '20px', aspectRatio: 1 }} alt="" /> Total Cost</>),
    cell: ({ row }) => `$${formatNumberWithSpaces(formatNumber(row.original.totalCostAmount)) || 0}`,
    // cell: EditCell,
    meta: {
      fieldKey: "groupTotalCost",
      fieldLabel: "Total Cost"
    }
  },
  {
    accessorKey: "amountPaid",
    filterKey: "AmountPaid",
    header: () => (<><img src={JobberLogo} style={{ width: '20px', aspectRatio: 1 }} alt="" /> Amount Paid</>),
    // cell: EditCell,
    cell: ({ row }) => `$${formatNumberWithSpaces(formatNumber(row.original.totalPaidAmount)) || 0}`,
    meta: {
      fieldKey: "groupAmountPaid",
      fieldLabel: "Paid"
    }
  },
  {
    accessorKey: "amountOwed",
    filterKey: "AmountOwed",
    header: () => (<><img src={JobberLogo} style={{ width: '20px', aspectRatio: 1 }} alt="" /> Amount Owed</>),
    // cell: EditCell,
    cell: ({ row }) => `$${formatNumberWithSpaces(formatNumber(row.original.totalOwedAmount)) || 0}`,
    meta: {
      fieldKey: "groupAmountOwed",
      fieldLabel: "Owed"
    }
  },
  {
    accessorKey: "QBtotalCost",
    filterKey: "QBTotalCost",
    header: () => (<><SiQuickbooks className='fs-5' /> Total Cost</>),
    cell: ({ row }) => `$${formatNumberWithSpaces(formatNumber(row.original.totalQBCost)) || 0}`,
  },
  {
    accessorKey: "QBamountPaid",
    filterKey: "QBAmountPaid",
    header: () => (<><SiQuickbooks className='fs-5' /> Amount Paid</>),
    cell: ({ row }) => `$${formatNumberWithSpaces(formatNumber(row.original.amountQBPaid)) || 0}`,
  },
  {
    accessorKey: "QBamountOwed",
    filterKey: "QBAmountOwed",
    header: () => (<><SiQuickbooks className='fs-5' /> Amount Owed</>),
    cell: ({ row }) => `$${formatNumberWithSpaces(formatNumber(row.original.amountQBOwed)) || 0}`,
  },
  {
    accessorKey: "totalAmountOwed",
    filterKey: "totalAmountOwed",
    header: "Total Amount Owed",
    cell: ({ getValue }) => `$${formatNumberWithSpaces(formatNumber(getValue())) || 0}`,
  },
  {
    accessorKey: "date",
    filterKey: "QUOTE_CREATED_AT",
    header: "Created Date",
    cell: ({ row }) => formatYearDateTimeDay(row.original.quote_createdAt),
  },
  {
    accessorKey: "approvedDate",
    filterKey: "APPROVED_AT",
    header: "Approved Date",
    cell: ({ row }) => (row.original.approved_at ? formatYearDateTimeDay(row.original.approved_at) : "-"),
  },
  {
    accessorKey: "actions",
    header: "Actions",
    isDisabled: true,
    cell: Actions
  },
];

export default createColumns;
