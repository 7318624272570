import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { Spinner } from "@jobber/components/Spinner";
import { showToast } from '@jobber/components/Toast';
import { Tooltip } from "@jobber/components/Tooltip";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { FaRecycle } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { IoMdSave } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { MdDeleteForever, MdReplay } from "react-icons/md";
import { SiQuickbooks } from "react-icons/si";
import { deleteFromServer, getFromServer, postToServer, putToServer } from "../../../../components/Common/requests";
import { Link } from "react-router-dom";

export default function Actions({ row, table }) {
  const meta = table.options.meta;
  const [open, setOpen] = useState(false);

  function setEditedRows(e) {
    e.stopPropagation();
    meta.handleEditedRows(e.currentTarget.dataset.name, row)
  }

  const { mutate: saveRowData, isPending: isRowSavePending } = useMutation({
    mutationFn: ({ row }) => putToServer(`quote/${meta.newTableData[row.index].client}/${meta.currentTabId}`, meta.newTableData[row.index].uniquePropertyAddresses),
    onError: (error) => {
      showToast({
        message: error.message || "Error updating row data!",
        variation: 'error'
      });
    },
    onSuccess({ data }, { elName, row }) {
      meta.updateData(row.index, 'uniquePropertyAddresses', data?.[0].uniquePropertyAddresses);
      meta.updateData(row.index, 'totalAmountOwed', data?.[0].totalAmountOwed);
      meta.handleEditedRows(elName, row)
      showToast({
        message: 'Quote updated successfully'
      });
      return true
    }
  })

  function handleSave(e) {
    e.stopPropagation();
    saveRowData({ e, row })
  }

  const { mutate: syncClient, isPending: isQBLoading } = useMutation({
    mutationFn: (clientName) => postToServer(`qb/sync-client/${clientName}`),
    onMutate: (variables) => {
      showToast({
        message: `Syncing ${variables} with Quickbooks`,
        variation: 'info'
      });
    },
    onError: (error) => {
      showToast({
        message: error.message || error.error || "Error syncing client with QuickBooks!",
        variation: 'error'
      });
    },
    onSuccess({ data }) {
      const { totalQBCost, amountQBPaid, amountQBOwed, totalAmountOwed } = data[0]
      meta.updateData(row.index, 'totalQBCost', totalQBCost);
      meta.updateData(row.index, 'amountQBPaid', amountQBPaid);
      meta.updateData(row.index, 'amountQBOwed', amountQBOwed);
      meta.updateData(row.index, 'totalAmountOwed', totalAmountOwed)
      showToast({
        message: 'Client synced successfully'
      });
      return true
    }
  })

  const { mutate: auditQuote, isPending: isAuditPending } = useMutation({
    mutationFn: () => getFromServer(`audit-quote/${meta.currentTabId}?clientId=${row.original.client}`),
    onMutate: () => {
      setOpen(false);
      showToast({
        message: `Auditing quote`,
        variation: 'info'
      });
    },
    onError: (error) => {
      showToast({
        message: error.message || error.error || "Error auditing quote!",
        variation: 'error'
      });
    },
    onSuccess({ data }) {
      meta.updateData(row.index, 'uniquePropertyAddresses', data.quotes?.[0].uniquePropertyAddresses);
      meta.updateData(row.index, 'totalAmountOwed', data.quotes?.[0].totalAmountOwed);
      meta.updateData(row.index, 'approved', data.quotes?.[0].approved);
      showToast({
        message: 'Quote audited successfully'
      });
      return true
    }
  })

  const { mutate: markAsDelete, isPending: isDeletePending } = useMutation({
    mutationFn: ({ isDeleted, permanent }) => deleteFromServer(!permanent ? `mark-quotes-as-deleted/${row.original.client}?isDeleted=${isDeleted}` : `quote/${row.original.client}`),
    onMutate: ({ isDeleted, permanent }) => {
      let message = isDeleted ? 'Quote moved to recycle page successfully' : 'Quote restored successfully'
      if (permanent) message = 'Quote deleted successfully'
      showToast({
        message: message,
        variation: 'info'
      });
    },
    onError: (error) => {
      showToast({
        message: error.message || error.error || "Error marking client!",
        variation: 'error'
      });
    },
    onSuccess(data) {
      meta.deleteData(row.original._id)
      showToast({
        message: data.message || 'Success'
      });
      return true
    }
  })

  return (
    <div className="d-flex align-items-center gap-1">
      {open &&
        <ConfirmationModal
          title={'Warning'}
          message={"Are you sure? We recommend taking backup"}
          open={open}
          confirmLabel="Audit"
          onConfirm={auditQuote}
          onCancel={() => setOpen(false)}
        />
      }
      {isRowSavePending || isQBLoading || isDeletePending || isAuditPending ? <Spinner /> : (
        <>
          {meta?.editedRows[row.id] ? (
            <>
              <IoMdSave className="fs-5 pointer" title="Save" onClick={handleSave} data-name="done" />
              <IoClose className="fs-5 pointer" title="Cancel" onClick={setEditedRows} data-name="cancel" />
            </>
          ) : (
            <>
              {meta.isRecycle ? (
                <>
                  <Tooltip message={"Restore"}>
                    <FaRecycle className="pointer fs-6 muted" onClick={() => markAsDelete({ isDeleted: false, permanent: false })} />
                  </Tooltip>
                  <Tooltip message={"Delete"}>
                    <MdDeleteForever className="pointer text-danger fs-5 muted" onClick={() => markAsDelete({ isDeleted: true, permanent: true })} />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip message={"Edit"}>
                    <Link
                      to={{ pathname: `/advance-quotes/${meta.currentTabId}/${row.original.client}/edit` }}
                    >
                      <FiEdit className="pointer fs-6 muted" />
                    </Link>
                    {/* <FiEdit className="pointer fs-6 muted" onClick={setEditedRows} data-name="edit" /> */}
                  </Tooltip>
                  <Tooltip message={"Audit Quote"}>
                    <MdReplay className="pointer text-success fs-5 muted" onClick={() => setOpen(true)} />
                  </Tooltip>
                  <Tooltip message={"Delete"}>
                    <MdDeleteForever className="pointer text-danger fs-5 muted" onClick={() => markAsDelete({ isDeleted: true, permanent: false })} />
                  </Tooltip>
                  <Tooltip message={"Sync QB"}>
                    <SiQuickbooks className="pointer fs-5 muted" onClick={() => syncClient(row.original.clientName)} />
                  </Tooltip>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
